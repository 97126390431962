// Generated by Framer (e83dae3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./Ocd7BrlKZ-0.js";

const cycleOrder = ["F7ncSdhwO", "hXU5_XX4y"];

const serializationHash = "framer-AuYq9"

const variantClassNames = {F7ncSdhwO: "framer-v-p540z4", hXU5_XX4y: "framer-v-wgax57"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {QqP3jQNpQ: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Dark: "hXU5_XX4y", Light: "F7ncSdhwO"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, mqGgawvaN: tap ?? props.mqGgawvaN, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "F7ncSdhwO"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, mqGgawvaN, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "F7ncSdhwO", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapeg5jis = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (mqGgawvaN) {
const res = await mqGgawvaN(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-p540z4", className, classNames)} data-framer-name={"Light"} data-highlight layoutDependency={layoutDependency} layoutId={"F7ncSdhwO"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapeg5jis} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({hXU5_XX4y: {"data-framer-name": "Dark"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QmUgVmlldG5hbSBQcm8tNTAw", "--framer-font-family": "\"Be Vietnam Pro\", \"Be Vietnam Pro Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-text-color": "var(--extracted-r6o4lv, rgb(242, 242, 242))"}}>Holovenko</motion.p></React.Fragment>} className={"framer-vk5j2h"} fonts={["GF;Be Vietnam Pro-500"]} layoutDependency={layoutDependency} layoutId={"ambaJCgYj"} style={{"--extracted-r6o4lv": "rgb(242, 242, 242)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-AuYq9.framer-abz1nw, .framer-AuYq9 .framer-abz1nw { display: block; }", ".framer-AuYq9.framer-p540z4 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 12px 0px 6px 0px; position: relative; width: min-content; }", ".framer-AuYq9 .framer-vk5j2h { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-AuYq9.framer-p540z4 { gap: 0px; } .framer-AuYq9.framer-p540z4 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-AuYq9.framer-p540z4 > :first-child { margin-top: 0px; } .framer-AuYq9.framer-p540z4 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 39.5
 * @framerIntrinsicWidth 93
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"hXU5_XX4y":{"layout":["auto","auto"]}}}
 * @framerVariables {"mqGgawvaN":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerOcd7BrlKZ: React.ComponentType<Props> = withCSS(Component, css, "framer-AuYq9") as typeof Component;
export default FramerOcd7BrlKZ;

FramerOcd7BrlKZ.displayName = "Logo Copy";

FramerOcd7BrlKZ.defaultProps = {height: 39.5, width: 93};

addPropertyControls(FramerOcd7BrlKZ, {variant: {options: ["F7ncSdhwO", "hXU5_XX4y"], optionTitles: ["Light", "Dark"], title: "Variant", type: ControlType.Enum}, mqGgawvaN: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerOcd7BrlKZ, [{family: "Be Vietnam Pro", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/bevietnampro/v11/QdVMSTAyLFyeg_IDWvOJmVES_HTEJl83T7wrcwap.woff2", weight: "500"}])